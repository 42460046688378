<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import MyLoader from "../../../ui/myLoader.vue";
import MyHeaderView from "../../../ui/myHeaderView.vue";

export default {
  name: "flujoProcesOrden",
  components: { MyLoader, MyHeaderView, draggable },
  data: function () {
    return {
      breadCrums: [
        {
          text: "Inicio",
          disabled: false,
          url: "Home",
        },
        {
          text: "Configuración",
          disabled: false,
          url: "Configuracion",
        },
        {
          text: "Flujo de procesos",
          disabled: false,
          url: "TProcesos",
        },
        {
          text: "Orden",
          disabled: true,
          url: "TProcesos",
        },
      ],
      itemsSort: [],
      dialogConfirmUndoSort: false,
      dialogConfirm: false,
      loadingUndoSort: false,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.tprocesos.procesos,
      loading: (state) => state.tprocesos.loading,
      loadingSave: (state) => state.tprocesos.loadingSave,
    }),
  },
  watch: {
    items: {
      handler() {
        this.setItemsSorts();
      },
      deep: true,
    },
  },
  methods: {
    setItemsSorts() {
      this.itemsSort = this.items.map((item, index) => {
        return {
          ...item,
          order: index,
        };
      });
    },
    gotoBack() {
      this.$router.push({
        name: "TProcesos",
      });
    },
    undoSort() {
      this.dialogConfirmUndoSort = true;
    },
    undoSortConfirm() {
      this.loadingUndoSort = true;
      setTimeout(() => {
        this.setItemsSorts();
        this.loadingUndoSort = false;
        this.dialogConfirmUndoSort = false;
      }, 1000);
    },
    saveSort() {
      this.dialogConfirm = true;
    },
    save() {},
  },
  mounted() {
    this.$store.dispatch("tprocesos/findProcesos");
    this.setItemsSorts();
  },
};
</script>
<template>
  <div>
    <my-header-view title="Flujo de procesos" :bread-crumbs="breadCrums" />
    <my-loader
      v-if="loading"
      title="Un momento por favor"
      subtitle="Estamos obteniendo los procesos existentes."
    />
    <v-row v-else class="pt-5">
      <v-col cols="12" class="">
        <v-card flat class="soft_shadow pa-5">
          <v-card-text>
            <v-row class="pb-10">
              <v-col cols="12">
                <v-alert
                  icon="mdi-lightbulb-on-outline"
                  prominent
                  text
                  color="primary"
                >
                  Puede mover los procesos segun el orden que sea necesario
                  utilizando:
                  <v-icon color="my_yellow"> mdi-drag-variant </v-icon>
                </v-alert>
              </v-col>
              <v-col
                cols="12"
                class="pb-10"
                style="max-height: 50vh; overflow-y: auto"
              >
                <draggable
                  v-model="itemsSort"
                  group="loans"
                  @start="drag = true"
                  @end="drag = false"
                  handle=".handle"
                >
                  <v-card
                    class="my-2"
                    outlined
                    v-for="(item, i) in itemsSort"
                    :key="item.order"
                  >
                    <v-card-text>
                      <v-list-item class="py-0 my-0">
                        <v-list-item-content class="pa-0 ma-0">
                          <v-row class="pa-0 ma-0 support--text">
                            <v-col cols="6" md="1">
                              <v-avatar color="secondary" size="30">
                                <strong class="white--text">
                                  {{ i + 1 }}
                                </strong>
                              </v-avatar>
                            </v-col>
                            <v-col cols="12" md="3" class="py-md-0">
                              <p class="pb-0 mb-0">
                                <span class="primary--text font-weight-medium">
                                  Nombre:
                                </span>
                                {{ item.prprcNombre }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon class="handle">
                            <v-icon color="my_yellow">
                              mdi-drag-variant
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="px-md-15 pb-5 pt-10 pt-md-0">
            <v-spacer></v-spacer>
            <v-btn
              :block="$vuetify.breakpoint.xs"
              @click="gotoBack"
              color="support"
              outlined
              :disabled="loadingSave"
            >
              volver
            </v-btn>
            <v-btn
              :block="$vuetify.breakpoint.xs"
              @click="undoSort"
              color="primary"
              outlined
              :disabled="loadingSave"
            >
              <v-icon left> mdi-undo-variant </v-icon>
              restablecer orden
            </v-btn>
            <v-btn
              :block="$vuetify.breakpoint.xs"
              @click="saveSort"
              color="primary"
              :loading="loadingSave"
              elevation="0"
            >
              guardar orden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogConfirmUndoSort" width="450" persistent>
      <v-card class="pa-2">
        <v-card-title class="text-h6 primary--text font-weight-bold">
          <v-icon large color="primary" class="pr-2">
            mdi-alert-circle-outline</v-icon
          >
          Restablecer orden
        </v-card-title>
        <v-card-text>
          Se perderan todos los cambios de posicion que has hecho
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="support"
            text
            @click="dialogConfirmUndoSort = false"
            :disabled="loadingUndoSort"
          >
            volver
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="undoSortConfirm"
            :loading="loadingUndoSort"
          >
            Si, restablecer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" width="450" persistent>
      <v-card class="pa-2">
        <v-card-title class="text-h6 primary--text font-weight-bold">
          <v-icon large color="primary" class="pr-2">
            mdi-alert-circle-outline</v-icon
          >
          Guardar orden
        </v-card-title>
        <v-card-text> Este será el nuevo orden para los procesos. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="support"
            text
            @click="dialogConfirm = false"
            :disabled="loadingSave"
          >
            volver
          </v-btn>
          <v-btn color="primary" outlined @click="save" :loading="loadingSave">
            Si, guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style></style>
<style scoped src="@/assets/css/globals.css"></style>
